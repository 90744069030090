import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { repo } from './firebase.service';

const path = 'profiles';

export const upsertProfile = async (profile, file) => {
  if (file && profile.photoURL !== file) {
    const result = await uploadFromBlobAsync(file.preview, `${profile.id}_${Date.now()}_${file.name}`);
    profile.photoURL = result;
  }

  await setDoc(doc(repo, path, profile.id), profile);
  return profile;
};

export const getProfile = async (id) => {
  const docRef = doc(repo, path, id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  }

  return undefined;
};

export const uploadFromBlobAsync = async (blobUrl, name) => {
  if (!blobUrl || !name) {
    return null;
  }

  const blob = await fetch(blobUrl).then((r) => r.blob());
  const storage = getStorage();
  const storageRef = ref(storage, name);
  const snapshot = await uploadBytes(storageRef, blob);
  return getDownloadURL(snapshot.ref);
};
