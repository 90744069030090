const schemaKeys = {
  mark_as_read: '',
  created_at: '',
  mark_as_unread: '',
  message: '',
  about: '',
  address: '',
  admin: '',
  basic: '',
  billing: '',
  changes_save: '',
  city: '',
  configuration: '',
  contrast: '',
  country: '',
  current: '',
  display_name_required: '',
  email_required: '',
  email_valid_email: '',
  email: '',
  favourite: '',
  feedback_send: '',
  feedback: '',
  field_required: '',
  free: '',
  fullscreen_exit: '',
  fullscreen: '',
  general: '',
  go_to_home: '',
  home: '',
  how_to_score: '',
  layout: '',
  logout_unable: '',
  logout: '',
  message_enter: '',
  month: '',
  my_courses: '',
  my_experience: '',
  my_info: '',
  my_processes: '',
  my_teaching: '',
  name: '',
  notifications: '',
  official_documents: '',
  overview: '',
  page_not_found_description: '',
  page_not_found: '',
  permission_denied_details: '',
  permission_denied: '',
  phone_number: '',
  photo_url_allowed: '',
  photo_url_required: '',
  photo_url: '',
  plan_cancel: '',
  plan_upgrade: '',
  plan: '',
  pro: '',
  processeses: '',
  profile: '',
  score_details: '',
  scores: '',
  settings: '',
  show_details: '',
  state: '',
  stretch_notes: '',
  stretch: '',
  subject: '',
  submit_success: '',
  submit: '',
  summary: '',
  theme: '',
  timeIntervals: '',
  update_success: '',
  upgrade_to_pro: '',
  user_delete: '',
  users_only: '',
  your_score: '',
  zip_code: '',
};

Object.keys(schemaKeys).forEach((k) => {
  schemaKeys[k] = k;
});

export { schemaKeys };
