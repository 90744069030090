import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Typography, Stack, Button, CircularProgress } from '@mui/material';
import { Navigate, useSearchParams } from 'react-router-dom';
// hooks
import { useResponsive } from '../hooks/use-responsive';
// components
import { Logo } from '../components/logo/logo';
import { Iconify } from '../components/iconify/iconify';
// services
import { useAuthContext } from '../auth/hooks/use-auth-context';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const { loading, authenticated, loginWithGoogle } = useAuthContext();
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('returnTo');

  if (loading) {
    return <CircularProgress />;
  }

  if (authenticated) {
    return <Navigate to={redirect ?? '/dashboard'} replace />;
  }

  return (
    <>
      <Helmet>
        <title> Login | PrOpo </title>
      </Helmet>

      <StyledRoot>
        <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        />

        {mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h4" gutterBottom>
              Sign in to PrOpo
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button fullWidth size="large" color="inherit" variant="outlined" onClick={loginWithGoogle}>
                <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />
              </Button>
            </Stack>
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
