// @mui
import Button from '@mui/material/Button';
// routes
import { RouterLink } from '../../routes/components/router-link';
// ----------------------------------------------------------------------
export function LoginButton({ sx }) {
  return (
    <Button component={RouterLink} href="/login" variant="outlined" sx={{ mr: 1, ...sx }}>
      Login
    </Button>
  );
}
