import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
// routes
import { Router } from './routes';
// providers
import { ThemeProvider } from './theme';
import { SnackbarProvider } from './components/snackbar/snackbar-provider';
import { ProgressBar } from './components/progress-bar/progress-bar';
import { MotionLazy } from './components/animate/motion-lazy';
import { LocalizationProvider } from './locales/localization-provider';
import { SettingsProvider } from './components/settings/context/settings-provider';
import { SettingsDrawer } from './components/settings/drawer/settings-drawer';
import { AuthProvider } from './auth/context/firebase/auth-provider';
import { AuthConsumer } from './auth/context/firebase/auth-consumer';
// components
import { StyledChart } from './components/chart/styles';
import { ScrollToTop } from './components/scroll-to-top/scroll-to-top';

// i18n
import './locales/i18n';

// ----------------------------------------------------------------------

export function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <AuthProvider>
            <LocalizationProvider>
              <SettingsProvider
                defaultSettings={{
                  themeDirection: 'ltr',
                  themeMode: 'light', // 'light' | 'dark'
                  themeContrast: 'default', // 'default' | 'bold'
                  themeLayout: 'vertical', // 'vertical' | 'horizontal' | 'mini'
                  themeStretch: true,
                }}
              >
                <ThemeProvider>
                  <MotionLazy>
                    <SnackbarProvider>
                      <SettingsDrawer />
                      <ProgressBar />
                      <ScrollToTop />
                      <StyledChart />
                      <AuthConsumer>
                        <Router />
                      </AuthConsumer>
                    </SnackbarProvider>
                  </MotionLazy>
                </ThemeProvider>
              </SettingsProvider>
            </LocalizationProvider>
          </AuthProvider>
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  );
}
