import { createContext, useContext } from 'react';
// ----------------------------------------------------------------------

const noOp = () => '';

export const SnackbarContext = createContext({ enqueueSnackbar: noOp, closeSnackbar: noOp });

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) throw new Error('useSnackbar must be use inside SnackbarProvider');

  return context;
};
