// ----------------------------------------------------------------------

export const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
};

// ----------------------------------------------------------------------

export const paths = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  // AUTH
  auth: {
    login: `${ROOTS.AUTH}/login`,
    verify: `${ROOTS.AUTH}/verify`,
    register: `${ROOTS.AUTH}/register`,
    forgotPassword: `${ROOTS.AUTH}/forgot-password`,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    feedback: `${ROOTS.DASHBOARD}/feedback`,
    processes: `${ROOTS.DASHBOARD}/processes`,
    process: `${ROOTS.DASHBOARD}/processes/:id/:name`,
    myProcesses: `${ROOTS.DASHBOARD}/my/processes`,
    myExperience: `${ROOTS.DASHBOARD}/my/experience`,
    myCourses: `${ROOTS.DASHBOARD}/my/courses`,
    myTeaching: `${ROOTS.DASHBOARD}/my/teaching`,
  },
  user: {
    root: `${ROOTS.DASHBOARD}/my/user`,
    account: `${ROOTS.DASHBOARD}/my/user/account`,
  },
  admin: {
    root: `${ROOTS.DASHBOARD}/admin`,
    feedback: `${ROOTS.DASHBOARD}/admin/feedback`,
  },
};
