import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
//
import { useAuthContext } from '../hooks/use-auth-context';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export function AuthGuard({ children }) {
  const { authenticated, isLoading } = useAuthContext();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!authenticated) {
    // Redirect the user to the home page.
    // Please! Close the mustache {{}}
    return <Navigate to={`/login?returnTo=${location.pathname}`} replace />;
  }

  return children;
}
