import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from '../../auth/hooks/use-auth-context';
// routes
import { paths } from '../../routes/paths';
// locales
import { useLocales, localeKeys } from '../../locales/use-locales';
// components
import { Label } from '../../components/label/label';

import { _userPlans } from '../../_mock/user';

// ----------------------------------------------------------------------

export function NavUpgrade() {
  const { user, upsertUser } = useAuthContext();

  const { t } = useLocales();

  const handleUpgradeToPro = useCallback(async () => {
    await upsertUser({ ...user, plan: _userPlans.pro.key });
  }, [user, upsertUser]);

  return (
    <Stack
      sx={{
        px: 2,
        py: 5,
        textAlign: 'center',
      }}
    >
      <Stack alignItems="center">
        <Box sx={{ position: 'relative' }}>
          <Avatar src={user?.photoURL} alt={user?.displayName} sx={{ width: 48, height: 48 }} />
          <Label
            color="success"
            variant="filled"
            sx={{
              top: -6,
              px: 0.5,
              left: 40,
              height: 20,
              position: 'absolute',
              borderBottomLeftRadius: 2,
            }}
          >
            {t(localeKeys.free)}
          </Label>
        </Box>

        <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.displayName}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.disabled' }}>
            {user?.email}
          </Typography>
        </Stack>

        <Button
          variant="contained"
          href={paths.minimalUI}
          target="_blank"
          rel="noopener"
          onClick={() => handleUpgradeToPro()}
        >
          {t(localeKeys.upgrade_to_pro)}
        </Button>
      </Stack>
    </Stack>
  );
}
