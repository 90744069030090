import PropTypes from 'prop-types';
import { useEffect, useCallback, useMemo, useState } from 'react';
import { auth } from '../../../services/firebase.service';
import { signInUser, signOutUser, userStateListener } from '../../../services/firebase-auth.service';
import { getProfile, upsertProfile } from '../../../services/profile-firestore.service';
//
import { AuthContext } from './auth-context';

// ----------------------------------------------------------------------

const defaultUser = {
  about: '',
  address: '',
  city: '',
  country: '',
  createdAt: Date.now(),
  displayName: '',
  email: '',
  id: '',
  opes: [],
  phoneNumber: '',
  photoURL: '',
  plan: '',
  role: '',
  state: '',
  zipCode: '',
  authenticated: false,
};

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(auth?.currentUser || defaultUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = userStateListener(async (user) => {
      setCurrentUser(await getCurrentProfile(user));
      setIsLoading(false);
    });
    return unsubscribe;
  }, [setCurrentUser, setIsLoading]);

  const loginWithGoogle = useCallback(async () => {
    const { user } = await signInUser();
    setCurrentUser({
      ...(await getCurrentProfile(user)),
      authenticated: true,
      role: 'user',
    });
  }, []);

  const upsertUser = useCallback(async (user, avatar) => {
    const newUser = await upsertProfile(user, avatar);
    setCurrentUser(newUser);
  }, []);

  const logout = useCallback(async () => {
    await signOutUser();
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = currentUser.authenticated ? 'authenticated' : 'unauthenticated';

  const status = isLoading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: currentUser,
      method: 'firebase',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      upsertUser,
      loginWithGoogle,
      logout,
    }),
    [status, currentUser, upsertUser, loginWithGoogle, logout]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

const getCurrentProfile = async (user) => {
  if (!user) {
    return defaultUser;
  }

  const profile = await getProfile(user.uid);
  if (profile) {
    return profile;
  }

  return upsertProfile({
    ...defaultUser,
    ...{
      id: user.uid,
      displayName: user.displayName,
      email: user.email,
      photoURL: user.photoURL,
      createdAt: Date.now(),
    },
  });
};
