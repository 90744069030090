import { useEffect } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Drawer from '@mui/material/Drawer';
// hooks
import { useResponsive } from '../../hooks/use-responsive';
// hooks
import { useAuthContext } from '../../auth/hooks/use-auth-context';
// components
import { Logo } from '../../components/logo/logo';
import { Scrollbar } from '../../components/scrollbar/scrollbar';
import { usePathname } from '../../routes/hooks/use-pathname';
import { NavSectionVertical } from '../../components/nav-section/vertical/nav-section-vertical';
//
import { NAV } from '../config-layout';
import { useNavData } from './config-navigation';
import { NavToggleButton } from '../_common/nav-toggle-button';
import { NavUpgrade } from '../_common/nav-upgrade';

// ----------------------------------------------------------------------
export function NavVertical({ openNav, onCloseNav }) {
  const { authenticated, user } = useAuthContext();

  const pathname = usePathname();

  const lgUp = useResponsive('up', 'lg');

  const navData = useNavData();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Logo sx={{ mt: 3, ml: 4, mb: 1 }} />

      <NavSectionVertical data={navData} config={{ currentRole: user?.role }} />

      <Box sx={{ flexGrow: 1 }} />

      {authenticated && user.plan !== 'pro' && <NavUpgrade />}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_VERTICAL },
      }}
    >
      <NavToggleButton />

      {lgUp ? (
        <Stack
          sx={{
            height: 1,
            position: 'fixed',
            width: NAV.W_VERTICAL,
            borderRight: (theme) => `dashed 1px ${theme.palette.divider}`,
          }}
        >
          {renderContent}
        </Stack>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          PaperProps={{
            sx: {
              width: NAV.W_VERTICAL,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
