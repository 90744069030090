export const organizers = {
  sermas: {
    title: 'Servicio Madrileño de Salud (SERMAS)',
    img: '/assets/images/organizers/sermas.png',
  },
  hufa: {
    title: 'Hospital Universitario Fundación Alcorcón',
    img: '/assets/images/organizers/sermas.png',
  },
  csuisp: {
    title: 'Conselleria de Sanitat Universal i Salut Pública',
    img: '/assets/images/organizers/csuisp.png',
  },
  polan: {
    title: 'Polan',
    img: '/assets/images/organizers/sescam.png',
  },
};

// ----------------------------------------------------------------------

export const baremo = {
  maxScore: 40,
  details: [
    {
      id: '1',
      name: 'Experiencia profesional',
      description: 'Experiencia profesional',
      formula: 'sum',
      max: 36,
      details: [
        {
          id: 'a1',
          name: 'Servicios en misma categoría Defensa',
          description:
            'Servicios prestados en la misma categoría y especialidad solicitada en la Red Hospitalaria de la Defensa.',
          formula: '$months x 0.30',
          max: 36,
        },
        {
          id: 'a2',
          name: 'Servicios en misma categoría Defensa (COVID)',
          description:
            'Servicios prestados en la misma categoría y especialidad solicitada en la Red Hospitalaria de la Defensa, durante período pandemia COVID.',
          formula: '$months x 0.60',
          max: 36,
        },
        {
          id: 'b1',
          name: 'Servicios en Red Pública en la U.E.',
          description:
            'Servicios prestados en la misma categoría y especialidad solicitada en el Servicio Público de Salud o equivalente en la U.E. y EE.lib.cir.trab.',
          formula: '$months x 0.18',
          max: 21.6,
        },
        {
          id: 'b2',
          name: 'Servicios en Red Pública en la U.E. (COVID)',
          description:
            'Servicios prestados en la misma categoría y especialidad solicitada en el Servicio Público de Salud o equivalente en la U.E. durante COVID.',
          formula: '$months x 0.36',
          max: 21.6,
        },
        {
          id: 'c1',
          name: 'Servicios en Red Privada',
          description:
            'Servicios prestados en la misma categoría y especialidad solicitada en la Red Hospitalaria Privada.',
          formula: '$months x 0.12',
          max: 14.4,
        },
        {
          id: 'd1',
          name: 'Servicios en distinta categoría Defensa',
          description:
            'Servicios prestados en distinta categoría y especialidad solicitada en la Red Hospitalaria de la Defensa.',
          formula: '$months x 0.06',
          max: 7.2,
        },
        {
          id: 'd2',
          name: 'Servicios en distinta categoría en Servicio Público',
          description:
            'Servicios prestados en distinta categoría y especialidad solicitada en el Servicio Público de Salud o equivalente en la U.E. y EE.lib.cir.trab.',
          formula: '$months x 0.03',
          max: 7.2,
        },
      ],
    },
    {
      id: '2',
      name: 'Formación',
      description: 'Formación',
      formula: 'sum',
      max: 1,
      details: [
        {
          id: '2.1',
          name: 'Actividad discente',
          description: 'Actividad discente',
          formula: '',
          max: 1,
          details: [
            {
              id: '2.1.a',
              name: 'Cursos en misma categoría Defensa',
              description:
                'Diplomas o certificados de cursos, expertos y máster cuyo contenido se encuentre relacionado directamente con la categoría a la que se opta, cuando estén organizados por el Ministerio de Defensa.',
              formula: '$credits x 0.30',
              max: 1,
            },
            {
              id: '2.1.b',
              name: 'Cursos en misma categoría por Entidades del Anexo I.',
              description:
                'Diplomas o certificados de cursos, expertos y máster cuyo contenido se encuentre relacionado directamente con la categoría a la que se opta, cuando estén organizados por Entidades del Anexo I.',
              formula: '$credits x 0.15',
              max: 1,
            },
          ],
        },
        {
          id: '2.2',
          name: 'Actividad docencia',
          description: 'Actividad docencia',
          formula: '',
          max: 1,
          details: [
            {
              id: '2.2.a',
              name: 'Cursos en misma categoría Defensa',
              description:
                'Por impartir cursos de formación de postgrado y formación continuada, directamente relacionados con la categoría a la que se opta, impartidos en el Ministerio de Defensa.',
              formula: '$credits x 0.30',
              max: 1,
            },
            {
              id: '2.2.b',
              name: 'Cursos en misma categoría por Entidades del Anexo I.',
              description:
                'Por impartir cursos de formación de postgrado y formación continuada, directamente relacionados con la categoría a la que se opta, impartidos en las entidades señaladas en el Anexo I.',
              formula: '$credits x 0.15',
              max: 1,
            },
          ],
        },
      ],
    },
    {
      id: '3',
      name: 'Méritos',
      description: 'Méritos',
      formula: 'sum',
      max: 2,
      details: [
        {
          id: '3.a',
          name: 'Por Cruz al Mérito',
          description: 'Por cada Cruz al Mérito Militar, Naval o Aeronáutico.',
          formula: '$price x 1.5',
          max: 2,
        },
        {
          id: '3.b',
          name: 'Por Medalla en Operación Balmis.',
          description: 'Por la Medalla conmemorativa de la Operación Balmis.',
          formula: '$price x 1.25',
          max: 2,
        },
        {
          id: '3.c',
          name: 'Por Mención Honorífica.',
          description: 'Por cada Mención Honorífica.',
          formula: '$price x 1.25',
          max: 2,
        },
        {
          id: '3.d',
          name: 'Por superar fase oposición en Defensa',
          description:
            'Por haber superado en los 5 años anteriores a esta convocatoria la fase de oposición de algún proceso selectivo de esta misma categoría de la Red Hospitalaria de la Defensa.',
          formula: '$price x 2',
          max: 2,
        },
      ],
    },
  ],
};

export function getProcessUrl(process) {
  return `/dashboard/processes/${process.id}/${process.titleShort}`;
}

export const processes = [
  {
    id: 'ef2c3eaa-0975-4fc2-a84d-a1d6536058de',
    status: 'new',
    title: 'Auxiliar Administrativo en SERMAS',
    titleShort: 'aux-administrativo-salud-madrid',
    titleLong: 'Oposiciones Auxiliar Administrativo de Servicios de Salud',
    organizer: organizers.sermas,
    publicationDate: new Date(2023, 5, 19),
    examDate: null,
    spots: 491,
    sourceUrl:
      'https://www.opobusca.com/ofertas/auxiliar-administrativo-de-servicios-de-salud/sermas-servicio-madrileno-de-salud/58047',
    territorialScope: 'Autonómico',
    territoryName: 'Madrid',
    type: 'Oferta',
    timeIntervals: [
      { title: 'Admitidos provisionales', time: new Date(2023, 5, 19) },
      { title: 'Admitidos definitivos', time: new Date(2023, 6, 1) },
      { title: 'Examen', time: new Date(2023, 8, 19) },
      { title: 'Nota provisionales', time: new Date(2023, 9, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
    ],
    documents: [
      {
        type: 'pdf',
        name: 'BOCM de 19/05/2023',
        description: 'Boletín Oficial de la C. de Madrid',
        url: 'https://www.bocm.es/boletin/CM_Orden_BOCM/2023/05/19/BOCM-20230519-1.PDF',
        publishedAt: new Date(2023, 5, 19),
      },
      {
        type: 'excel',
        name: 'Baremo',
        description: '',
        url: 'https://excel-online.softonic.com/',
        publishedAt: new Date(2023, 5, 19),
      },
    ],
    metrics: [
      { label: 'Exámen', value: 80 },
      { label: 'Experiencia', value: 0 },
      { label: 'Cursos', value: 10 },
      { label: 'Medallas', value: 10 },
    ],
    baremo,
  },
  {
    id: 'cd696f13-e6d2-4c57-bc49-eeedab3447f6',
    status: undefined,
    title: 'Auxiliar Administrativo de Servicios de Salud en Alcorcón',
    titleShort: 'aux-administrativo-salud-alcorcon',
    titleLong:
      'Oposiciones de Auxiliar Administrativo de Servicios de Salud en Hospital Universitario Fundación Alcorcón',
    organizer: organizers.hufa,
    publicationDate: new Date(2023, 5, 19),
    examDate: null,
    spots: 5,
    sourceUrl:
      'https://www.opobusca.com/ofertas/auxiliar-administrativo-de-servicios-de-salud/hospital-universitario-fundacion-alcorcon/58059',
    territorialScope: 'Autonómico',
    territoryName: 'Madrid',
    type: 'Oferta',
    timeIntervals: [
      { title: 'Admitidos provisionales', time: new Date(2024, 5, 19) },
      { title: 'Admitidos definitivos', time: new Date(2024, 6, 1) },
      { title: 'Examen', time: new Date(2024, 8, 19) },
      { title: 'Nota provisionales', time: new Date(2024, 9, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
    ],
    documents: [
      {
        type: 'excel',
        name: 'Baremo',
        description: '',
        url: 'https://excel-online.softonic.com/',
        publishedAt: new Date(2023, 5, 19),
      },
      {
        type: 'pdf',
        name: 'BOCM de 19/05/2023',
        description: 'Boletín Oficial de la C. de Madrid',
        url: 'https://www.bocm.es/boletin/CM_Orden_BOCM/2023/05/19/BOCM-20230519-1.PDF',
        publishedAt: new Date(2023, 5, 19),
      },
    ],
    metrics: [
      { label: 'Exámen', value: 100 },
      { label: 'Experiencia', value: 0 },
      { label: 'Cursos', value: 0 },
      { label: 'Medallas', value: 0 },
    ],
    baremo,
  },
  {
    id: '86556407-d0b0-4d79-8f2a-a4987405eb49',
    status: undefined,
    title: 'Auxiliar Administrativo de Salud en Valencia',
    titleShort: 'aux-administrativo-salud-valencia',
    titleLong:
      'Convocatoria Auxiliar Administrativo de Servicios de Salud en Conselleria de Sanitat Universal i Salut Pública',
    organizer: organizers.csuisp,
    publicationDate: new Date(2023, 4, 1),
    examDate: null,
    spots: 1,
    sourceUrl:
      'https://www.opobusca.com/convocatorias/auxiliar-administrativo-de-servicios-de-salud/conselleria-de-sanitat-universal-i-salut-publica/92399',
    territorialScope: 'Autonómico',
    territoryName: 'Valencia',
    type: 'Oferta',
    timeIntervals: [
      { title: 'Admitidos provisionales', time: new Date(2023, 5, 19) },
      { title: 'Admitidos definitivos', time: new Date(2023, 10, 1) },
      { title: 'Examen', time: new Date(2023, 11, 19) },
      { title: 'Nota provisionales', time: new Date(2023, 12, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
    ],
    documents: [
      {
        type: 'pdf',
        name: 'Categorias',
        description: 'Anexo de categorías profesionales',
        url: 'https://www.gva.es/descarregues/2023/04/20122-anexo_I_cat_prof_cas_val.pdf',
        publishedAt: new Date(2023, 1, 5),
      },
      {
        type: 'pdf',
        name: 'Bases',
        description: 'Resolución',
        url: 'https://www.gva.es/descarregues/2023/04/20128-convoc_2023_list_abierta_perm_cas_firmado.pdf',
        publishedAt: new Date(2023, 2, 5),
      },
      {
        type: 'link',
        name: 'Web',
        description: 'Boletín Oficial de la Provincia',
        url: 'https://www.gva.es/es/inicio/atencion_ciudadano/buscadores/busc_empleo_publico/detalle_oposiciones?id_emp=91454&id_lista=12',
        publishedAt: new Date(2023, 4, 5),
      },
    ],
    metrics: [
      { label: 'Exámen', value: 60 },
      { label: 'Experiencia', value: 20 },
      { label: 'Cursos', value: 15 },
      { label: 'Medallas', value: 5 },
    ],
    baremo,
  },
  {
    id: 'fdc3f28c-81e0-45b7-b41e-bc8e1cda32c2',
    status: undefined,
    title: 'Auxiliar Administrativo de Salud en Polan (Toledo)',
    titleShort: 'aasspt',
    titleLong: 'Convocatoria Auxiliar Administrativo de Servicios de Salud en Polan (Toledo)',
    organizer: organizers.polan,
    publicationDate: new Date(2023, 7, 29),
    examDate: null,
    spots: 1,
    sourceUrl:
      'https://www.opobusca.com/convocatorias/auxiliar-administrativo-de-servicios-de-salud/polan-toledo/95741',
    territorialScope: 'Local',
    territoryName: 'Toledo',
    type: 'Oferta',
    timeIntervals: [
      { title: 'Admitidos provisionales', time: new Date(2023, 5, 19) },
      { title: 'Admitidos definitivos', time: new Date(2023, 6, 1) },
      { title: 'Examen', time: new Date(2023, 8, 19) },
      { title: 'Nota provisionales', time: new Date(2023, 9, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
    ],
    documents: [
      {
        type: 'link',
        name: 'BOP 8/2/2023',
        description: 'Boletín Oficial de la Provincia',
        url: 'https://bop.diputoledo.es/webEbop/ebopResumen.jsp?bop_number=26&insert_year=2023&anexo=0',
        publishedAt: new Date(2023, 2, 8),
      },
      {
        type: 'pdf',
        name: 'BOE 29/07/2023',
        description: 'Boletín Oficial del Estado',
        url: 'https://boe.es/boe/dias/2023/07/29/pdfs/BOE-A-2023-17474.pdf',
        publishedAt: new Date(2023, 5, 19),
      },
    ],
    metrics: [
      { label: 'Exámen', value: 60 },
      { label: 'Experiencia', value: 20 },
      { label: 'Cursos', value: 15 },
      { label: 'Medallas', value: 5 },
    ],
    baremo,
  },
  {
    id: '8a0495c3-4dbc-45f4-9a2b-55e08d90cbba',
    status: undefined,
    title: 'Auxiliar Administrativo de Salud en Polan (Toledo)',
    titleShort: 'aasspt',
    titleLong: 'Convocatoria Auxiliar Administrativo de Servicios de Salud en Polan (Toledo)',
    organizer: organizers.polan,
    publicationDate: new Date(2023, 7, 29),
    examDate: null,
    spots: 1,
    sourceUrl:
      'https://www.opobusca.com/convocatorias/auxiliar-administrativo-de-servicios-de-salud/polan-toledo/95741',
    territorialScope: 'Local',
    territoryName: 'Toledo',
    type: 'Oferta',
    timeIntervals: [
      { title: 'Admitidos provisionales', time: new Date(2023, 5, 19) },
      { title: 'Admitidos definitivos', time: new Date(2023, 6, 1) },
      { title: 'Examen', time: new Date(2023, 7, 1) },
      { title: 'Nota provisionales', time: new Date(2023, 7, 2) },
      { title: 'Nota definitivas', time: new Date(2024, 1, 2) },
    ],
    documents: [
      {
        type: 'link',
        name: 'BOP 8/2/2023',
        description: 'Boletín Oficial de la Provincia',
        url: 'https://bop.diputoledo.es/webEbop/ebopResumen.jsp?bop_number=26&insert_year=2023&anexo=0',
        publishedAt: new Date(2023, 2, 8),
      },
      {
        type: 'pdf',
        name: 'BOE 29/07/2023',
        description: 'Boletín Oficial del Estado',
        url: 'https://boe.es/boe/dias/2023/07/29/pdfs/BOE-A-2023-17474.pdf',
        publishedAt: new Date(2023, 5, 19),
      },
    ],
    metrics: [
      { label: 'Exámen', value: 60 },
      { label: 'Experiencia', value: 20 },
      { label: 'Cursos', value: 15 },
      { label: 'Medallas', value: 5 },
    ],
    baremo,
  },
];
