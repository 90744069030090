import { useMemo } from 'react';
import { SvgIcon } from '@mui/material';
import { Feedback, Favorite, Book, AccountBox, CardMembership, LibraryBooks } from '@mui/icons-material';
// routes
import { paths } from '../../routes/paths';
// locales
import { useLocales, localeKeys } from '../../locales/use-locales';
import { useAuthContext } from '../../auth/hooks/use-auth-context';

const icon = (component) => <SvgIcon component={component} sx={{ width: 1, height: 1 }} />;

// ----------------------------------------------------------------------

export function useNavData() {
  const { user } = useAuthContext();
  const { t } = useLocales();

  const data = useMemo(() => {
    const items = [
      {
        subheader: t(localeKeys.overview),
        items: [
          {
            title: t(localeKeys.processeses),
            path: paths.dashboard.processes,
            icon: icon(LibraryBooks),
          },
        ],
      },
      {
        subheader: t(localeKeys.my_info),
        items: [
          {
            title: t(localeKeys.my_processes),
            path: paths.dashboard.myProcesses,
            icon: icon(Favorite),
            roles: ['admin', 'user'],
            disabled: user.role === '',
          },
          {
            title: t(localeKeys.my_experience),
            path: paths.dashboard.myExperience,
            icon: icon(AccountBox),
            roles: ['admin', 'user'],
            disabled: user.role === '',
          },
          {
            title: t(localeKeys.my_courses),
            path: paths.dashboard.myCourses,
            icon: icon(Book),
            roles: ['admin', 'user'],
            disabled: user.role === '',
          },
          {
            title: t(localeKeys.my_teaching),
            path: paths.dashboard.myTeaching,
            icon: icon(CardMembership),
            roles: ['admin', 'user'],
            disabled: user.role === '',
          },
        ],
      },
    ];

    if (user.role === 'admin') {
      items.push({
        subheader: t(localeKeys.admin),
        items: [
          {
            title: t(localeKeys.feedback),
            path: paths.admin.feedback,
            icon: icon(Feedback),
          },
        ],
      });
    }
    return items;
  }, [t, user]);

  return data;
}
