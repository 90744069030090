import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { lazily } from 'react-lazily';
import { Suspense } from 'react';

// layouts
import { DashboardLayout } from './layouts/dashboard/dashboard-layout';
import { LoadingScreen } from './components/loading-screen/loading-screen';
import { SimpleLayout } from './layouts/simple/simple-layout';

import { LoginPage } from './pages/login-page';
import { Page404 } from './pages/page404';
import { ROOTS } from './routes/paths';
import { AuthGuard } from './auth/guard/auth-guard';
import { AdminGuard } from './auth/guard/admin-guard';

const { MyCoursesPage } = lazily(() => import('./pages/my-courses-page'));
const { MyExperiencePage } = lazily(() => import('./pages/my-experience-page'));
const { MyProcessesPage } = lazily(() => import('./pages/my-processes-page'));
const { MyTeachingPage } = lazily(() => import('./pages/my-teaching-page'));
const { ProcessPage } = lazily(() => import('./pages/process-page'));
const { ProcessesPage } = lazily(() => import('./pages/processes-page'));
const { AccountPage } = lazily(() => import('./pages/account-page'));
const { FeedbackPage } = lazily(() => import('./pages/feedback-page'));
const { AdminFeedbackPage } = lazily(() => import('./pages/admin-feedback-page'));

// ----------------------------------------------------------------------

export function Router() {
  const routes = useRoutes([
    {
      path: `${ROOTS.DASHBOARD}/my`,
      element: (
        <AuthGuard>
          <DashboardLayout>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </DashboardLayout>
        </AuthGuard>
      ),
      children: [
        { path: 'processes', element: <MyProcessesPage /> },
        { path: 'experience', element: <MyExperiencePage /> },
        { path: 'courses', element: <MyCoursesPage /> },
        { path: 'teaching', element: <MyTeachingPage /> },
        {
          path: 'user',
          children: [
            { element: <AccountPage />, index: true },
            { path: 'account', element: <AccountPage /> },
          ],
        },
        { element: <Navigate to="/dashboard/my/processes" />, index: true },
      ],
    },
    {
      path: `${ROOTS.DASHBOARD}/admin`,
      element: (
        <DashboardLayout>
          <AdminGuard>
            <Suspense fallback={<LoadingScreen />}>
              <Outlet />
            </Suspense>
          </AdminGuard>
        </DashboardLayout>
      ),
      children: [
        { element: <AdminFeedbackPage />, index: true },
        { path: 'feedback', element: <AdminFeedbackPage /> },
      ],
    },
    {
      path: ROOTS.DASHBOARD,
      element: (
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      ),
      children: [
        { path: 'processes/:id/:name', element: <ProcessPage /> },
        { path: 'processes', element: <ProcessesPage /> },
        { path: 'feedback', element: <FeedbackPage /> },
        { element: <Navigate to="/dashboard/processes" />, index: true },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
