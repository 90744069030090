import PropTypes from 'prop-types';
// @mui
import { useCallback } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

// theme
import { bgBlur } from '../../theme/css';
// hooks
import { useOffSetTop } from '../../hooks/use-off-set-top';
import { useResponsive } from '../../hooks/use-responsive';
// components
import { Logo } from '../../components/logo/logo';
import { SvgColor } from '../../components/svg-color/svg-color';
import { useSettingsContext } from '../../components/settings/context/settings-context';
//
import { HEADER, NAV } from '../config-layout';

import { useAuthContext } from '../../auth/hooks/use-auth-context';
import { Iconify } from '../../components/iconify/iconify';
import { Searchbar } from '../_common/searchbar/searchbar';
import { AccountPopover } from '../_common/account-popover';
import { SettingsButton } from '../_common/settings-button';
import { LanguagePopover } from '../_common/language-popover';
import { NotificationsPopover } from '../_common/notifications-popover/notifications-popover';
import { LoginButton } from '../_common/login-button';
import { useLocales, localeKeys } from '../../locales/use-locales';

// ----------------------------------------------------------------------
Header.propTypes = {
  onOpenNav: PropTypes.any,
};

export function Header({ onOpenNav }) {
  const { user } = useAuthContext();
  const theme = useTheme();

  const settings = useSettingsContext();
  const navigate = useNavigate();
  const { t } = useLocales();

  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const lgUp = useResponsive('up', 'lg');

  const offset = useOffSetTop(HEADER.H_DESKTOP);

  const offsetTop = offset && !isNavHorizontal;

  const openFeedback = useCallback(() => {
    navigate('/dashboard/feedback', { replace: true });
  }, [navigate]);

  const renderContent = (
    <>
      {lgUp && isNavHorizontal && <Logo sx={{ mr: 2.5 }} />}

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Searchbar />

      <Stack flexGrow={1} direction="row" alignItems="center" justifyContent="flex-end" spacing={{ xs: 0.5, sm: 1 }}>
        <Button
          variant="soft"
          color="primary"
          startIcon={<Iconify icon="ic:outline-feedback" />}
          onClick={() => openFeedback()}
        >
          {t(localeKeys.feedback_send)}
        </Button>

        <LanguagePopover />

        <NotificationsPopover />

        <SettingsButton />

        {user.authenticated ? <AccountPopover /> : <LoginButton />}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
