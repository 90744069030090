import { createContext } from 'react';
//
// ----------------------------------------------------------------------

export const AuthContext = createContext({
  user: {},
  method: 'firebase',
  loading: true,
  authenticated: false,
  unauthenticated: true,
  upsertUser: () => Promise.resolve(),
  loginWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});
