import { m } from 'framer-motion';
// @mui
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// hooks
import { useAuthContext } from '../hooks/use-auth-context';
import { useLocales, localeKeys } from '../../locales/use-locales';
// assets
import { ForbiddenIllustration } from '../../assets/illustrations/forbidden-illustration';
// components
import { MotionContainer, varBounce } from '../../components/animate';

// ----------------------------------------------------------------------

export function AdminGuard({ children, sx }) {
  const { user } = useAuthContext();
  const { t } = useLocales();

  if (user?.role !== 'admin') {
    return (
      <Container component={MotionContainer} sx={{ textAlign: 'center', ...sx }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" sx={{ mb: 2 }}>
            {t(localeKeys.permission_denied)}
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>{t(localeKeys.permission_denied_details)}</Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <ForbiddenIllustration
            sx={{
              height: 260,
              my: { xs: 5, sm: 10 },
            }}
          />
        </m.div>
      </Container>
    );
  }

  return children;
}
